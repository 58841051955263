<template>
	<div>
		<PhotoScroll :data="photoScrollImages" v-if="mobile && data.photoScrollOnMobile" :clickable="clickable"></PhotoScroll>
		<div v-else>
			<p class="fs20 bold" :class="{margin4: !data.desc, mb6: data.desc}" v-html="data.title" v-if="data.title"></p>
			<p class="margin4 lh28" v-html="data.desc" v-if="data.desc"></p>
			<div class="imagesTWoCol" :class="data.additionalClass">
				<div class="imagesTWoCol__left">
					<div class="imagesTWoCol__images" :class="{margin4: data.leftBottomText}">

						<template v-for="image in data.leftImages">
							<div v-if="clickable" class="clickablePhoto" @click="openPopupSlider(image.image)">
								<img v-lazy="image.image" :class="{clickable: clickable}">
							</div>
							<img v-else v-lazy="image.image" :class="{clickable: clickable}" @click="openPopupSlider(image.image)">
						</template>

					</div>
					<p v-html="data.leftBottomText" class="lh28"></p>
				</div>
				<div class="imagesTWoCol__right">
					<div class="imagesTWoCol__images" :class="{fix: data.rightImgFixed}" :style="{left: fixData.left, top: fixData.top, bottom: fixData.bottom, width: fixData.width, position: fixData.position}">
						
						<template v-for="image in data.rightImages">
							<div v-if="clickable" class="clickablePhoto" @click="openPopupSlider(image.image)">
								<img v-lazy="image.image" :class="{clickable: clickable}">
							</div>
							<img v-else v-lazy="image.image" :class="{clickable: clickable}" @click="openPopupSlider(image.image)">
						</template>

					</div>
				</div>
			</div>
		</div>
		<ImagesPopupSlider ref="imagesPopupSlider"></ImagesPopupSlider>
	</div>
</template>

<style lang="sass">
	@import '@/assets/sass/images/ImagesTwoCol'
</style>

<script>
	import PhotoScroll from '@/components/app/PhotoScroll'
	import ImagesPopupSlider from '@/components/app/ImagesPopupSlider'

	export default {
		props: ['data', 'clickable'],
		data: () => ({
			mobile: false,
			photoScrollImages: [],
			fullImages: [],
			fixData: {
				fixed: false,
				left: 0,
				width: 0,
				top: 0,
				bottom: 0,
				position: 'relative',
			}
		}),
		methods: {
			imgFixPos: function(){
				if(this.data.rightImgFixed){
					var element = this.$el.getElementsByClassName("fix")[0];
					var block = element.closest('.imagesTWoCol');
					var parent = element.closest('.imagesTWoCol__left');
					if(!parent){
						parent = element.closest('.imagesTWoCol__right');
					}

					var parentTop = block.offsetTop;
					var parentLeft = parent.offsetLeft;
					var parentHeight = block.offsetHeight;
					var parentWidth = parent.offsetWidth;
					var itemTop = parent.offsetTop;
					var scrollTop = window.pageYOffset;

					if(scrollTop + element.offsetHeight + 28 >= parentTop + parentHeight){
						this.fixData.top = "auto";
						this.fixData.bottom = "0px";
						this.fixData.position = "absolute";
						this.fixData.width = parentWidth + "px";
						this.fixData.left = "";
					} else if(scrollTop + 28 < parentTop){
						this.fixData.top = "";
						this.fixData.bottom = "";
						this.fixData.position = "";
						this.fixData.width = "";
						this.fixData.left = "";
					} else {
						this.fixData.top = "28px";
						this.fixData.bottom = "auto";
						this.fixData.position = "fixed";
						this.fixData.width = parentWidth + "px";
						this.fixData.left = parentLeft + "px";
					}
				}
			},
			checkMobile: function(){
				if(window.innerWidth <= 600){
					this.mobile = true;
				} else{
					this.mobile = false;
				}
			},
			openPopupSlider: function(image){
				var index = 0;
				var findedActiveElement = false;

				var images = [];
				this.photoScrollImages.images.forEach( function(element) {
					if(element.fullImage){
						images.push(element.fullImage);
					} else{
						images.push(element.image);
					}

					if(element.image != image && !findedActiveElement){
						index++;
					} else{
						findedActiveElement = true;
					}
				});


				if(this.clickable && !this.noClickEvent){
					this.$refs.imagesPopupSlider.openPopupSlider(images, index);
				}
			}
		},
		mounted(){
			var comp = this;
			comp.imgFixPos();
			comp.checkMobile();
			window.addEventListener('resize', function(event){
				comp.imgFixPos();
				comp.checkMobile();
			});
			window.addEventListener('scroll', function(event){
				comp.imgFixPos();
			});

			comp.photoScrollImages.images = [];
			comp.data.leftImages.forEach(function(image, index) {
				if(image.fullImage){
					comp.photoScrollImages.images.push({
						image: image.image,
						fullImage: image.fullImage
					})
				} else{
					comp.photoScrollImages.images.push({
						image: image.image
					})
				}
			});

			comp.data.rightImages.forEach(function(image, index) {
				if(image.fullImage){
					comp.photoScrollImages.images.push({
						image: image.image,
						fullImage: image.fullImage
					})
				} else{
					comp.photoScrollImages.images.push({
						image: image.image
					})
				}
			});

			if(comp.data.leftBottomText){
				comp.photoScrollImages.bottomText = comp.data.leftBottomText;
			}
		},
		components: {
			PhotoScroll,
			ImagesPopupSlider
		}
	}
</script>